import { faArrowLeft, faCamera, faDice, faFire, faHome, faUsers } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useEffect, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { Link, NavLink, useLocation, useNavigate } from "react-router-dom";
import Avatar from "../Component/Avatar";
import LocaleSelector from "../Component/LocaleSelector/LocaleSelector";
import MobileSideMenu from "../Component/MobileSideMenu";
import useLocale from "../Hook/locale";
import useIsMobile from "../Hook/mobile";
import { PLATFORM } from "../Hook/platform";
import { displayPopin } from "../Hook/popins";
import useUser from "../Hook/user";
import add from "../svg/add.svg";
import UserMenu from "./UserMenu";
import { changePageTitle } from "../utils";

function Header({ goBack }: { goBack?: boolean }) {
  const user = useUser();
  const display = displayPopin();
  const navigate = useNavigate();
  const intl = useIntl();
  const locale = useLocale();
  const location = useLocation();
  const detectMobile = useIsMobile();

  useEffect(() => {
    if(location.pathname === "/members"){
      changePageTitle(`${intl.formatMessage({id:"menu_desktop__members"})} - ${PLATFORM!.platform!.name}`)
    }else if(location.pathname === "/top"){
      changePageTitle(`${intl.formatMessage({id:"topandprogress__head_title"})} - ${PLATFORM!.platform!.name}`)
    }else if(location.pathname === "/webcams"){
      changePageTitle(`${intl.formatMessage({id:"webcam_head_title"})} - ${PLATFORM!.platform!.name}`)
    }else if(location.pathname === "/challenge"){
      changePageTitle(`${intl.formatMessage({id:"challenge__head_title"})} - ${PLATFORM!.platform!.name}`)
    }else if(location.pathname === "/desabonnement"){
      changePageTitle(`${intl.formatMessage({id:"unsub__meta_titre"})} - ${PLATFORM!.platform!.name}`)
    }else if(location.pathname === "/search"){
      changePageTitle(`${intl.formatMessage({id:"search__head_title"})} - ${PLATFORM!.platform!.name}`)
    }else if(location.pathname === "/cgus"){
      changePageTitle(`${intl.formatMessage({id:"menu__cgu"})} - ${PLATFORM!.platform!.name}`)
    }else if(location.pathname === "/privacy"){
      changePageTitle(`${intl.formatMessage({id:"menu__privacy"})} - ${PLATFORM!.platform!.name}`)
    }else if(location.pathname === "/2257"){
      changePageTitle(`${intl.formatMessage({id:"menu__exemption"})} - ${PLATFORM!.platform!.name}`)
    }else if(location.pathname === "/dmca"){
      changePageTitle(`${intl.formatMessage({id:"menu__dmca"})} - ${PLATFORM!.platform!.name}`)
    }else if(location.pathname === "/contact"){
      changePageTitle(`${intl.formatMessage({id:"menu__contact"})} - ${PLATFORM!.platform!.name}`)
    }else{
      let domains:any = {
        "ephemia.com": "Ephemia: le réseau social éphémère depuis 2014",
        "snapamateur.com": "Snap Amateur: les snaps des amateurs de sexe",
        "instantjmm.com": "Instant JMM: le club privé de JMM pour chatter et s'envoyer des médias privés",
        "xstorysnap.com": " Xstory Snap: le club privé de Xstory pour chatter et échanger des snaps",
        "voissnap.com": "Voissnap: les snaps pour chauffer et flirter en dehors de Voissa"
      }
      if(domains[PLATFORM!.platform!.domain]){
        changePageTitle(domains[PLATFORM!.platform!.domain])
      }else{
        changePageTitle(PLATFORM!.platform!.name)
      }
    }
    
  }, [location, intl]);

  if (!user || !user.informations) return null;
  const activeStyle = "text-primary relative px-2 flex gap-2 items-center [&>div]:!block"

 

  return (
    <>
      <div id="header-wrapper" className="flex items-center justify-between h-16 lg:h-16 w-screen mx-auto bg-cards  px-8">
        <div className="max-w-7xl flex justify-between mx-auto w-full">
          <div className="flex flex-1 gap-8 items-center justify-center lg:justify-start">

            {detectMobile && goBack ? <div className="absolute top-5 left-7 lg:hidden" onClick={() => navigate(-1)}><FontAwesomeIcon className="h-6" icon={faArrowLeft} /></div> : <MobileSideMenu />}
            <img onClick={() => navigate("/")} className="max-h-8 lg:max-h-8 interact" src={PLATFORM.platform?.logo} />
            {!detectMobile.isMobile && <>
              <NavLink className={({ isActive }) => isActive ? activeStyle : " px-2 flex gap-2 items-center"} to="/"><FontAwesomeIcon icon={faHome}/><FormattedMessage id="menu__home" /><div className="absolute hidden w-full h-0.5 bg-primary -bottom-[20px] left-0"></div></NavLink>
              <NavLink className={({ isActive }) => isActive ? activeStyle : " px-2 flex gap-2 items-center"} to="/members"><FontAwesomeIcon icon={faUsers}/><FormattedMessage id="menu_desktop__members" /><div className="absolute hidden w-full h-0.5 bg-primary -bottom-[20px] left-0"></div></NavLink>
              <NavLink className={({ isActive }) => isActive ? activeStyle : " px-2 flex gap-2 items-center"} to="/top"><FontAwesomeIcon icon={faFire}/><FormattedMessage id="menu_desktop__top" /><div className="absolute hidden w-full h-0.5 bg-primary -bottom-[20px] left-0"></div></NavLink>
              <NavLink className={({ isActive }) => isActive ? activeStyle : " px-2 flex gap-2 items-center"} to="/webcams"><FontAwesomeIcon icon={faCamera}/><FormattedMessage id="menu_desktop__webcams" /><div className="absolute hidden w-full h-0.5 bg-primary -bottom-[20px] left-0"></div></NavLink>
              <NavLink className={({ isActive }) => isActive ? activeStyle : " px-2 flex gap-2 items-center"} to="/challenge"><FontAwesomeIcon icon={faDice}/><FormattedMessage id="menu__challenges" /><div className="absolute hidden w-full h-0.5 bg-primary -bottom-[20px] left-0"></div></NavLink>
            </>
            }
          </div>
          {!detectMobile.isMobile && <div className="mr-4 flex items-center">
            {user.informations.type === "guest" ? (
              <div onClick={() => display("login")}><FormattedMessage id="menu__login" /></div>
            ) : (
              <div className="flex gap-4 items-center">
                <Link to={{ pathname: "/uploader" }} className="flex gap-2 cursor-pointer">
                  <FormattedMessage id="menu__uploader" />
                  <img className="h-6 w-6" src={add} alt="Add Epheme" />
                </Link>
                <DesktopMenu user={user} />
              </div>
            )}
          </div>}
          {!detectMobile.isMobile && <LocaleSelector headerPosition />}
        </div>
      </div >
      {detectMobile.isMobile && <div id="top-mob-ads" className="flex justify-center items-center"><div className="w-screen max-w-7xl" id="ad-snapstory-1"></div></div>}
    </>
  );
}

function DesktopMenu({ user }: any) {
  const [open, setOpen] = useState(false);
  return (<div className="relative">
    <div onClick={() => setOpen(!open)} className="cursor-pointer h-10 w-10 rounded-full overflow-hidden"><Avatar member={user.informations} /></div>
    {open && <div className="fixed z-[200] bottom-0 left-0 right-0 top-0 bg-black opacity-55 cursor-pointer" onClick={() => setOpen(false)}>
      </div>}
    {open && <div className="fixed z-[210] flex flex-col gap-4 max-w-xl w-screen top-0 bottom-0 right-0 p-4 rounded-xl bg-popinbg">
      <UserMenu setOpen={setOpen} open={open} />
    </div>}
  </div>)
}

export default Header;
